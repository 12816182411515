import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { withRouter } from "../common/with-router";
import "./loginform2.css";
import { ToastContainer, toast } from "react-toastify";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      otp: "",
      loading: false,
      message: "",
      correctmessage: "", 
    };
  }
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSendOTP = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    this.setState({
      loading: true,
      message: "",
    });

    const { email } = this.state;

    // Make an API call to send OTP to the provided email
    axios
     .post("https://nepal-prod.ubsbill.com/testapp/api/auth/store/forgot-password", {
        email: email, // Use the email from state
     })
     .then((response) => {
        console.log("OTP Send Successfully");
        // toast.success("OTP Send Successfully");

        // Clear the email input field after successful OTP send
        this.setState({
         loading: false,
         email: "", // Reset the email field to empty
         correctmessage: "OTP Send Successfully",
        });
       window.location.href = "/resetpassword"
     })
     .catch((error) => {

        axios
        .post("https://nepal-prod.ubsbill.com/testapp/api/auth/Tech/forgot-password", {
         email: email, // Use the email from state
        })
        .then((response) => {
         console.log("OTP Send Successfully");
        //  toast.success("OTP Send Successfully");

         // Clear the email input field after successful OTP send
         this.setState({
            loading: false,
            email: "", // Reset the email field to empty
            correctmessage: "OTP Send Successfully",
         });
        window.location.href = "/resetpassword"
        })
        .catch((error) => {
    
         axios
         .post("https://nepal-prod.ubsbill.com/testapp/api/auth/user/user-forgot-password", {
            email: email, // Use the email from state
         })
         .then((response) => {
            console.log("OTP Send Successfully");
            // toast.success("OTP Send Successfully");
    
            // Clear the email input field after successful OTP send
            this.setState({
             loading: false,
             email: "", // Reset the email field to empty
             correctmessage: "OTP Send Successfully",
            });


             window.location.href = "/resetpassword"
         })
         .catch((error) => {
        
    
            if (error.response && error.response.status === 404) {
             // Handle the case where the email does not exist
             this.setState({
                loading: false,
                message: "Email does not exist. Please enter a register email.",
             });
            } else {
             // Handle other errors
             this.setState({
                loading: false,
                message: "Failed to send OTP. Please try again.",
             });
            }
         });
        });
     });
};


  render() {
    return (
      <div className="forgot-pass-page background animation">
        <img src="login.jpg" style={{ filter: " blur(1.5px)" }} />
        <div className="container-fluid d-flex justify-content-center align-items-center p-5" style={{ height: '100vh', marginTop: "-100vh" }}>
        <div className="card card-height border-info">
            <h4 className="text-center text-info mt-3 font-weight-bold"style={{fontSize:"3.5vh"}}><i class="fa-solid fa-key"></i> Forgot Password</h4>
            <Form
              className="mt-2 form-gap justify-content-center d-flex flex-column align-items-center" 
              onSubmit={this.handleSendOTP}
              ref={(c) => {
                this.form = c;
              }}
            >
              <div className="input-group mb-3">
              <span className="input-group-text button-with-icon icons">
                  <i className="fa-solid fa-envelope fa-icon"></i>
                </span>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter Email"
                  style={{fontSize:"2vh" , height: "5vh",}}
                  name="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  required
                />
              </div>

              <div className="form-group container21 ">
              <button
                  className="btn btn-block text-white d-flex justify-content-center align-items-center mt-4" style={{ backgroundColor: "#03989e",fontSize:"2vh" , maxHeight:"5vh" }}
                >
                  <span className="font-weight-bold">Send OTP <i class="fa-solid fa-right-to-bracket"></i> </span>
                </button>


              </div>
            </Form>
            <div className="text-center position-relative mb-4">
              {this.state.correctmessage && (
                <div className="success-message " style={{fontSize:"2.2vh", fontWeight:"700"}}>
                {this.state.correctmessage}
        </div> 
              )}
              {this.state.message && (

<div className="error-message text-danger" style={{fontSize:"2.2vh"}}>
  {this.state.message}
</div> 

)}
            </div>
            <div className=" text-center container-move position-relative margin-top-2"style={{fontSize:"2.5vh"}}>
              <Link to="/resetpassword" className="text-decoration-none text-info ">
                Reset Password?
              </Link>

              <p className=" text-dark"style={{fontSize:"2.5vh"}}>
                Customer support :{" "}
                <a href="mailto:syntiaro.dev@gmail.com">syntiaro.dev@gmail.com
</a>
              </p>
            </div>

          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={1000}
          limit={1}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnHover={false} 
          theme="dark" />

      </div>
    );
  }
}

export default ForgotPassword;