import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import "./loginform2.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";

const PasswordResetForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    otp: "",
    newPassword: "",
    comfirmPassword: "",
  });

  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [comfirmPassword, setComfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showComfirmPassword, setShowComfirmPassword] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.newPassword !== formData.comfirmPassword) {
      toast.error("New password and confirmation do not match");
      return;
    }
    try {
      const response = await fetch(
        "https://nepal-prod.ubsbill.com/testapp/api/auth/store/reset-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        // Password reset was successful
        console.log("Password reset successful"); // Log success to the console
        toast.success("Password reset successful"); // Show an alert
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        const successData = await response.json();
        setMessage(successData.message);
        setIsError(false);
      } else {
        const response = await fetch(
          "https://nepal-prod.ubsbill.com/testapp/api/auth/user/user-reset-password",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );
  
        if (response.ok) {
          // Password reset was successful
          console.log("Password reset successful"); // Log success to the console
          toast.success("Password reset successful"); // Show an alert
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          const successData = await response.json();
          setMessage(successData.message);
          setIsError(false);
       
          
          } else {
            const response = await fetch(
              "https://nepal-prod.ubsbill.com/testapp/api/auth/Tech/reset-password",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
              }
            );
      
            if (response.ok) {
              // Password reset was successful
              console.log("Password reset successful"); // Log success to the console
              toast.success("Password reset successful"); // Show an alert
              setTimeout(() => {
                window.location.reload();
              }, 1);
              const successData = await response.json();
              setMessage(successData.message);
              setIsError(false);
           
              
              } else {
                // Password reset failed
                const errorData = await response.text(); // Read response as text
                setMessage(`Password reset failed: ${errorData}`);
                setIsError(true);
                toast.error(errorData);
                console.error("Password reset error:", errorData);
              }
            }
          }
        }
      
     catch (error) {
      toast.error("An error occurred during password reset.");
 
     }
  };

  return (
    <div className="forgot-pass-page background animation">
    <img src="login.jpg" style={{ filter: " blur(1.5px)" }} />
    <div
      className="container-fluid d-flex justify-content-center align-items-center p-5"
      style={{ height: "100vh", marginTop: "-100vh" }}
    >
      <div className="card border-info ">
        <h4
          className="text-center text-info mt-3 font-weight-bold"
          style={{ marginBottom: "3vh", fontSize: "3.5vh" }}
        >
          <i class="fa-solid fa-rotate-right"></i> Reset Password
        </h4>
        <Form
          onSubmit={handleSubmit}
          className="col-md-12 d-flex justify-content-center align-items-left flex-column"
        >
          <div className="input-group mb-3 text65">
            <span className="input-group-text  button-with-icon icons">
              <i className="fa-solid fa-envelope fa-icon"></i>
            </span>

            <input
              type="email"
              id="email"
              name="email"
              className="login__input5"
              placeholder=" Email "
              value={formData.email}
              onChange={handleInputChange}
              style={{
                width: "25vh",
                fontSize: "2vh",
                height: "5vh",
                border: "1px solid #ccc",
              }}
            />
          </div>

          <div className="input-group mb-3 text65 ">
            <span className="input-group-text  button-with-icon icons ">
              <i class="fa-solid fa-check fa-icon"></i>
            </span>

            <input
              type="text"
              id="otp"
              name="otp"
              className="login__input5"
              placeholder="OTP "
              value={formData.otp}
              onChange={handleInputChange}
              style={{
                width: "25vh",
                fontSize: "2vh",
                height: "5vh",
                border: "1px solid #ccc",
              }}
            />
          </div>

          <div className="form-group ">
            <div className="input-group rounded ">
              <input
                type={showPassword ? "text" : "password"}
                id="newPassword"
                name="newPassword"
                className="login__input5 input5 rounded-left"
                placeholder="New Password"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters"
                value={formData.newPassword}
                onChange={handleInputChange}
                required
                style={{
                  fontSize: "2vh",
                  margin: 0,
                  padding: "0.5em 0",
                  paddingLeft: "1em",
                  height: "5vh",
                  border: "1px solid #ccc",
                }}
              />

              <div className="input-group-append ">
                <span
                  className={`toggle-password ${showPassword ? "show" : ""}`}
                  class="d-flex justify-content-center align-items-center rounded-right"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    fontSize: "1.6vh",
                    border: "1px solid #ccc",
                    height: "5vh",
                    minWidth: "3vw",
                  }}
                >
                  {showPassword ? "Hide" : "Show"}
                </span>
              </div>
            </div>
          </div>

          {/* <div className="form-group text65">
            <div className="input-group">
              <div className="form-group">
                <div className="password-input">
                  <div
                    className="input-container"
                    style={{ width: "40vh", height: "5vh" }}
                  >
                    <input
                      type={showPassword ? "text" : "password"}
                      id="newPassword"
                      name="newPassword"
                      className="login__input5"
                      placeholder="New Password"
                      value={formData.newPassword}
                      onChange={handleInputChange}
                      required
                      style={{ width: "25vh", fontSize: "2vh" }}
                    />
                    <span
                      className={`toggle-password ${
                        showPassword ? "show" : ""
                      }`}
                      onClick={() => setShowPassword(!showPassword)}
                      style={{ fontSize: "1.6vh" }}
                    >
                      {showPassword ? "Hide" : "Show"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="form-group text65 ">
            <div className="input-group rounded ">
              <input
                type={showComfirmPassword ? "text" : "password"}
                id="comfirmPassword"
                name="comfirmPassword"
                className="login__input5 input5 rounded-left"
                placeholder="Confirm Password "
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters"
                value={formData.comfirmPassword}
                onChange={handleInputChange}
                required
                style={{
                  fontSize: "2vh",
                  margin: 0,
                  padding: "0.5em 0",
                  paddingLeft: "1em",
                  height: "5vh",
                  border: "1px solid #ccc",
                }}
              />

              <div className="input-group-append ">
                <span
                  className={`toggle-password ${
                    showComfirmPassword ? "show" : ""
                  }`}
                  class="d-flex justify-content-center align-items-center rounded-right"
                  onClick={() => setShowComfirmPassword(!showComfirmPassword)}
                  style={{
                    fontSize: "1.6vh",
                    border: "1px solid #ccc",
                    height: "5vh",
                    minWidth: "3vw",
                  }}
                >
                  {showComfirmPassword ? "Hide" : "Show"}
                </span>
              </div>
            </div>
          </div>

          {/* <div className="form-group text65" style={{marginTop:"15px"}}>
            <div className="input-group">
              <div className="form-group">
                <div className="password-input">
                  <div
                    className="input-container"
                    style={{
                      width: "40vh",
                      height: "5vh",
                      marginTop: "-17px",
                    }}
                  >
                    <input
                      type={showComfirmPassword ? "text" : "password"}
                      id="comfirmPassword"
                      name="comfirmPassword"
                      className="login__input5"
                      placeholder="Confirm Password "
                      value={formData.comfirmPassword}
                      onChange={handleInputChange}
                      required
                      style={{ width: "25vh", fontSize: "2vh" }}
                    />
                    <span
                      className={`toggle-password ${
                        showComfirmPassword ? "show" : ""
                      }`}
                      onClick={() =>
                        setShowComfirmPassword(!showComfirmPassword)
                      }
                      style={{ fontSize: "1.6vh" }}
                    >
                      {showComfirmPassword ? "Hide" : "Show"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

<div className="form-group">
              <button
                className="btn btn-block text-white text-center"
                style={{ backgroundColor: "#03989e" }}
                type="submit"
              >
                <span
                  className="font-weight-bold"
                  style={{ fontSize: "2.5vh" }}
                >
                  Reset <i class="fa-solid fa-right-to-bracket"></i>{" "}
                </span>
              </button>
            </div>
        
        </Form>

          <div className="mt-2 text-center"style={{fontSize:"2.4vh"}}>
            <Link
              to="/login"
              className="text-decoration-none text-info font-weight-bold"
            >
              Log In?
            </Link>

            <p className="mt-2 text-dark"style={{fontSize:"2.5vh"}}>
              Customer support :{" "}
              <a href="mailto:syntiaro.dev@gmail.com">syntiaro.dev@gmail.com
</a>
            </p>
          </div>
        </div>
      </div>{" "}
      <ToastContainer
        position="top-right"
        autoClose={1000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnHover={false} 
        theme="dark"
      />
    </div>
  );
};
export default PasswordResetForm;
