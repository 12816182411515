import React, { Component, createRef } from "react";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AuthService from "./services/auth.service";
import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import Sidebar from "./components/sidebar.component";
import Dashbord from "./pages/dashboard";
import UserDashboard from "./pages/userdashboard";
import {
  Overview,
  Order_list,
  Order,
  Update_Order,
  Bill_list,
} from "./pages/overview";
import { Billing } from "./pages/billing";
import Adduser from "./pages/adduser.component";
import { Update_User } from "./pages/adduser.component";
import UserSidebar from "./components/usersidebar.component";
import { Roleaccess, Updateroleaccess } from "./pages/roleaccess";
import RoleList from "./pages/rolelist";
import UserList from "./pages/userlist";
import EventBus from "./common/EventBus";
import Header from "./pages/header";
import { Update_vendor, Vendor } from "./pages/vendor";
import Payment, { Update_payment, Payment_Gateway } from "./pages/payment";
import VendorInventory, {
  Update_Vendor_Inventory,
} from "./pages/VendorInventory";
import Inventory, { Inventory_list, Update_Inventory } from "./pages/Inventory";
import { Food, Food_list, Update_food } from "./pages/food";
import AddOn, { AddOn_list, Update_addon } from "./pages/add_ons";
import {
  Payment_list,
  Reports,
  Vendor_list,
  Vendor_Invoice_List,
  Balance_list,
} from "./pages/Reports";
import Taxsetting, {
  Settings,
  Payment_setting,
  Super_setting,
  Tech_setting,
  Update_Tax,
} from "./pages/settings";
import Storelist from "./pages/storelist";
import Technician from "./components/technician.component";
import AdminSidebar from "./components/adminsidebar.component";
import SupportSidebar from "./components/supportsidebar.component";
import Supportlist from "./pages/supportlist";
import {
  ProductForm,
  Receipe,
  Receipe_list,
  Update_Recipe,
} from "./pages/receipe";
import ForgotPassword from "./pages/forgotpassword";
import TechForgotUserPassword from "./pages/techforgotpassword";
import SuperForgotUserPassword from "./pages/superforgotpassword";
import ForgotUserPassword from "./pages/forgotuserpassword";
import Resetpassword from "./pages/resetpassword";
import Resetuserpassword from "./pages/resetuserpassword";
import SuperResetpassword from "./pages/superresetpassword";
import TechResetpassword from "./pages/techresetpassword";
import Freetrial from "./components/freetrial.component";
import BalanceForm from "./components/balanceform.component";
import Subscription from "./pages/subscription";
import { PendingOrder } from "./pages/pendingorder";
import NotificationForm from "./pages/NotificationForm";
import CategoryButton from "./pages/categoryButton";
import { Kot } from "./pages/kot";
import { Shortcut } from "./pages/shortcut";
import { Customer_list } from "./pages/customerlist";
import { Income_list } from "./pages/incomelist";
import superadminService from "./services/superadmin.service";
import technicianService from "./services/technician.service";
import adduserService from "./services/adduser.service";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
    this.state = {
      lastActivityTime: Date.now(),
      showSupportBoard: false,
      showSuperAdminBoard: false,
      showAdminBoard: false,
      showUserBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();
    if (user) {
      this.setState({
        currentUser: user,
        showSupportBoard: user.roles.includes("ROLE_SUPPORT"),
        showSuperAdminBoard: user.roles.includes("ROLE_SUPER_ADMIN"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
        showUserBoard: user.roles.includes("ROLE_USER"),
      });
    }
    EventBus.on("logout", () => {
      this.logOut();
    });

    this.activityListener = () => {
      this.setState({ lastActivityTime: Date.now() });
    };

    this.checkInactiveTime = () => {
      const { lastActivityTime } = this.state;
      const inactiveTime = Date.now() - lastActivityTime;
      const inactiveThreshold = 3 * 60 * 1000;

      if (inactiveTime > inactiveThreshold) {
        window.location.reload();
      }
    };

    document.addEventListener("mousemove", this.activityListener);
    document.addEventListener("keydown", this.activityListener);

    this.intervalId = setInterval(this.checkInactiveTime, 10000); // Check every 10 seconds
  }
  componentWillUnmount() {
    document.removeEventListener("mousemove", this.activityListener);
    document.removeEventListener("keydown", this.activityListener);
    clearInterval(this.intervalId);

    // Assuming EventBus is correctly implemented
    EventBus.remove("logout");
  }

  logOut() {
    const user = AuthService.getCurrentUser();

    if (user) {
      if (user.roles.includes("ROLE_ADMIN")) {
        AuthService.logout();
        this.setState({
          showSupportBoard: false,
          showSuperAdminBoard: false,
          showAdminBoard: false,
          showUserBoard: false,
          currentUser: undefined,
        });
        localStorage.clear();
      } else if (user.roles.includes("ROLE_SUPER_ADMIN")) {
        superadminService.logout();

        this.setState({
          showSupportBoard: false,
          showSuperAdminBoard: false,
          showAdminBoard: false,
          showUserBoard: false,
          currentUser: undefined,
        });
        localStorage.clear();
      } else if (user.roles.includes("ROLE_SUPPORT")) {
        technicianService.logout();
        this.setState({
          showSupportBoard: false,
          showSuperAdminBoard: false,
          showAdminBoard: false,
          showUserBoard: false,
          currentUser: undefined,
        });
        localStorage.clear();
      } else if (user.roles.includes("ROLE_USER")) {
        adduserService.logout();
        this.setState({
          showSupportBoard: false,
          showSuperAdminBoard: false,
          showAdminBoard: false,
          showUserBoard: false,
          currentUser: undefined,
        });
        localStorage.clear();
      } else {
        AuthService.logout();
        localStorage.removeItem("user");
        this.setState({
          showSupportBoard: false,
          showSuperAdminBoard: false,
          showAdminBoard: false,
          showUserBoard: false,
          currentUser: undefined,
        });
        localStorage.clear();
      }
    }
    // Reset state
    this.setState({
      showSupportBoard: false,
      showSuperAdminBoard: false,
      showAdminBoard: false,
      showUserBoard: false,
      currentUser: undefined,
    });
  }

  /*-------------SUNDER------ sidebar height: and width: ------*/
  render() {
    const isLoggedIn = AuthService.isLoggedIn();
    const {
      currentUser,
      showSupportBoard,
      showAdminBoard,
      showUserBoard,
      showSuperAdminBoard,
    } = this.state;
    const navbar = { background: "#03989e", height: "10vh" };

    //---------------for store logout ----------------

    return (
      <div>
        <nav
          className="navbar navbar-expand navbar-dark fixed-top"
          style={navbar}
        >
          <a class="navbar-brand p-0 logo12" href="#">
            <img
              src={process.env.PUBLIC_URL + "/vyanjan.jpeg"}
              className=""
              style={{
                width: "7vw",
                height: "6.5vh",
                marginLeft: "10vh",
                borderRadius: "40px",
              }}
              alt=""
            />
          </a>

          {currentUser && <></>}

          <div className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to={"/home"} className="nav-link active"></Link>
            </li>

            {showSupportBoard && (
              <>
                <SupportSidebar />
                <li className="nav-item">
                  <Link to={"/tech"} className="nav-link active"></Link>
                </li>
                <li className="nav-item ml-5 mt-2">
                  <div className="p-2" style={{ marginTop: "-0.6vh" }}>
                    <h6
                      className="mt-2 text-white text-center mx-2"
                      style={{ fontSize: "2.2vh" }}
                    >
                      <i class="fa-solid fa-id-card-clip fa-lg mr-1"></i>{" "}
                      Technician Support Id : {currentUser.techid}
                    </h6>
                  </div>
                </li>
              </>
            )}

                        {showSuperAdminBoard && (
                            <>
                                <Sidebar />
                                <li className="nav-item"></li>
                                <li className="nav-item ml-5 mt-2">
                                    <div className="p-2" style={{ marginTop: "-0.6vh" }}>
                                        <h6
                                            className="mt-2 text-white text-center mx-2"
                                            style={{ fontSize: "2.2vh" }}
                                        >
                                            <i
                                                class="fa-solid fa-id-card-clip fa-lg mr-1"
                                                style={{ fontSize: "2.4vh" }}
                                            ></i>{" "}
                                            Super Admin Id : {currentUser.superid}
                                        </h6>
                                    </div>
                                </li>
                            </>
                        )}
                        {showAdminBoard && (
                            <>
                                <AdminSidebar />
                                <li className="nav-item"></li>
                                <li className="nav-item ml-5 mt-2">
                                    <div className="p-2" style={{ marginTop: "-0.6vh" }}>
                                        <h6
                                            className="mt-2 text-white text-center mx-2"
                                            style={{ fontSize: "2.2vh" }}
                                        >
                                            <i
                                                class="fa-solid fa-id-card-clip fa-lg mr-1"
                                                style={{ fontSize: "2.4vh" }}
                                            ></i>{" "}
                                            Store Registration No : {currentUser.regiNum}
                                        </h6>
                                    </div>
                                </li>
                            </>
                        )}
                        {showUserBoard && (
                            <>
                                <UserSidebar />
                                <li className="nav-item">
                                    <Link to={"/user"} className="nav-link active"></Link>
                                </li>
                                <li className="nav-item ml-5 mt-1">
                                    <div className="p-2" style={{ marginTop: "-0.4vh" }}>
                                        <h6
                                            className="mt-2 text-white text-center mx-2"
                                            style={{ fontSize: "2.2vh" }}
                                        >
                                            <i
                                                class="fa-solid fa-id-card-clip fa-lg mr-1"
                                                style={{ fontSize: "2.4vh" }}
                                            ></i>{" "}
                                            User Id : {currentUser.id}
                                        </h6>
                                    </div>
                                </li>
                            </>
                        )}
                        {currentUser && <></>}
                    </div>
                    {currentUser ? (
                        <div className="navbar-nav ml-auto">
                            <Header />
                            <li className="nav-item mr-5" style={{ marginRight: "-40vh" }}>
                                <ul
                                    className="navbar-nav me-4 mb-2 mb-lg-0"
                                    style={{ fontSize: "2.4vh", marginTop: "0.5vh" }}
                                >
                                    <li className="nav-item dropdown p-1">
                                        <a
                                            className="nav-link active dropdown-toggle fw-bold ml-4 text-capitalize"
                                            href="/"
                                            id="navbarDropdown"
                                            role="button"

                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <i
                                                className="fa-solid fa-circle-user fa-lg mr-1"
                                                style={{ marginLeft: "-10vh" , textTransform:"l" }}
                                            ></i>{" "}
                                            {currentUser.username}
                                        </a>
                                        <ul
                                            className="dropdown-menu text-white"
                                            aria-labelledby="navbarDropdown"
                                        >
                                            {showAdminBoard && (
                                                <>
                                                    <li>
                                                        <a className="dropdown-item " href="/settings">
                                                            <i class="fa-solid fa-gear"></i> Edit Profile
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item "
                                                            onClick={() => this.logOut()}
                                                            href="/login"
                                                        >
                                                            <i class="fa-solid fa-right-from-bracket"></i>
                                                            Logout here
                                                        </a>
                                                    </li>{" "}
                                                </>
                                            )}
                                            {showSuperAdminBoard && (
                                                <>
                                                    <li>
                                                        <a
                                                            className="dropdown-item "
                                                            href="/settings/super_setting"
                                                        >
                                                            <i class="fa-solid fa-gear"></i> Edit Profile
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item "
                                                            onClick={() => this.logOut()}
                                                            href="/login"
                                                        >
                                                            <i class="fa-solid fa-right-from-bracket"></i>
                                                            Logout here
                                                        </a>
                                                    </li>
                                                </>
                                            )}
                                            {showSupportBoard && (
                                                <>
                                                    <li>
                                                        <a
                                                            className="dropdown-item "
                                                            href="/settings/tech_setting"
                                                        >
                                                            <i class="fa-solid fa-gear"></i> Edit Profile
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item "
                                                            onClick={() => this.logOut()}
                                                            href="/login"
                                                        >
                                                            <i class="fa-solid fa-right-from-bracket"></i>
                                                            Logout here
                                                        </a>
                                                    </li>
                                                </>
                                            )}
                                            {showUserBoard && (
                                                <>
                                                    <li>
                                                        <a
                                                            className="dropdown-item "
                                                            onClick={() => this.logOut()}
                                                            href="/login"
                                                        >
                                                            <i class="fa-solid fa-right-from-bracket"></i>
                                                            Logout here
                                                        </a>
                                                    </li>
                                                </>
                                            )}
                                            <li>
                                                <hr className="dropdown-divider " />
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <div
                               
                                className="ml-5"
                               
                        ></div></div>
                    ) : (
                        <div className="navbar-nav ml" style={{ marginRight: "95px" }}>

              <Link to="/login">
                <button
                  className="custom12"
                  style={{ fontWeight: "500", textcolor: "#03989e" }}
                >
                  <i className="fa-lg fa-solid fa-users"></i> Login
                </button>
              </Link>

              <div className="fixed-bottom p-4 text-center" style={navbar}>
                <div
                  className="text-white footertext"
                  style={{ fontSize: "2.5vh" }}
                >
                  @Copyright 2023 Design & Developed By SYNTIARO
                </div>
              </div>
            </div>
          )}
        </nav>

        <Routes>
          {/* *********************************ONLY LOGGED OUT PERSON WHO WANT TO LOGIN  CAN ACCESS IT********************************** */}
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route
            path="/techforgotpassword"
            element={<TechForgotUserPassword />}
          />
          <Route
            path="/superforgotpassword"
            element={<SuperForgotUserPassword />}
          />
          <Route path="/userforgotpassword" element={<ForgotUserPassword />} />
          <Route path="/resetpassword" element={<Resetpassword />} />
          <Route path="/resetuserpassword" element={<Resetuserpassword />} />
          <Route path="/forgotuserpassword" element={<ForgotUserPassword />} />
          <Route path="/superresetpassword" element={<SuperResetpassword />} />
          <Route path="/techresetpassword" element={<TechResetpassword />} />
          {/* **************************************************************************************************************************** */}

          {/* ****************************************ALL CAN ACCESS IT***************************************** */}
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/home" element={<Home />} />
          {/* ************************************************************************************************** */}

          {/* ***********************************BOTH SUPERADMIN & TECHNICIAN CAN ACCESS IT*********************************** */}
          {(this.state.showSupportBoard || this.state.showSuperAdminBoard) && (
            <Route path="/register" element={<Register />} />
          )}
         
          {(this.state.showSupportBoard || this.state.showSuperAdminBoard) && (
            <Route path="/freetrial" element={<Freetrial />} />
          )}
          {(this.state.showSupportBoard || this.state.showSuperAdminBoard) && (
            <Route path="/storelist" element={<Storelist />} />
          )}
          {(this.state.showSupportBoard || this.state.showSuperAdminBoard) && (
            <Route path="/subscription" element={<Subscription />} />
          )}

          {/* ****************************************************************************************************************** */}

          {/* *******************************ONLY SUPER ADMIN  CAN ACCESS IT********************************** */}

          {this.state.showSuperAdminBoard && (
            <Route path="/technician" element={<Technician />} />
          )}

          {this.state.showSuperAdminBoard && (
            <Route path="/supportlist" element={<Supportlist />} />
          )}

          {/* ************************************************************************************************** */}

          {/* *********************************ONLY TECHNICIAN  CAN ACCESS IT********************************** */}

          {this.state.showSupportBoard && (
            <Route path="/settings/tech_setting" element={<Tech_setting />} />
          )}

          {this.state.showSupportBoard && (
            <Route path="/notification" element={<NotificationForm />} />
          )}

          {/* ************************************************************************************************** */}

          {/* *********************************ONLY STORE ADMIN  CAN ACCESS IT********************************** */}
          {this.state.showAdminBoard && (
            <Route path="/dashbord" element={<Dashbord />} />
          )}
          {this.state.showAdminBoard && (
            <Route path="/user/adduser" element={<Adduser />} />
          )}

          {/* ************************************************************************************************** */}

          {/* *********************************ONLY USER CAN ACCESS IT********************************** */}

          {this.state.showUserBoard && (
            <Route path="/userdashbord" element={<UserDashboard />} />
          )}

          {/* ************************************************************************************************** */}

          {/* ***********************************BOTH SUPERADMIN & TECHNICIAN CAN ACCESS IT*********************************** */}
          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/balanceform" element={<BalanceForm />} />
          )}
          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/overview" element={<Overview />} />
          )}
          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/shortcut" element={<Shortcut />} />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/overview/order" element={<Order />} />
          )}
          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/pendingorder" element={<PendingOrder />} />
          )}
          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/overview/order_list" element={<Order_list />} />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/billing/:id" element={<Billing />} />
          )}

          {this.state.showAdminBoard && (
            <Route path="/user/update_user/:id" element={<Update_User />} />
          )}

          {this.state.showAdminBoard && (
            <Route path="/user/userlist" element={<UserList />} />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/role/roleaccess" element={<Roleaccess />} />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/role/rolelist" element={<RoleList />} />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/food/food" element={<Food />} />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/Food/Food_list" element={<Food_list />} />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/payment" element={<Payment />} />
          )}
          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route
              path="/payment/update_payment/:id"
              element={<Update_payment />}
            />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route
              path="/payment/payment_gateway/:id"
              element={<Payment_Gateway />}
            />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/vendor" element={<Vendor />} />
          )}
          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/vendorinventory" element={<VendorInventory />} />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route
              path="/vendor/update_vendor/:id"
              element={<Update_vendor />}
            />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route
              path="/vendorinventory/update_vendorinventory/:id"
              element={<Update_Vendor_Inventory />}
            />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/inventory" element={<Inventory />} />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route
              path="/inventory/inventory_list"
              element={<Inventory_list />}
            />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route
              path="/inventory/update_inventory/:id"
              element={<Update_Inventory />}
            />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/food/update_food/:id" element={<Update_food />} />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/addon/update_addon/:id" element={<Update_addon />} />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/food/add_ons" element={<AddOn />} />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/AddOn/Addon_list" element={<AddOn_list />} />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/reports" element={<Reports />} />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/reports/vendor_list" element={<Vendor_list />} />
          )}
          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route
              path="/balanceform/balance_list"
              element={<Balance_list />}
            />
          )}
          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/reports/payment_list" element={<Payment_list />} />
          )}
          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/overview/bill_list" element={<Bill_list />} />
          )}
          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route
              path="/reports/vendor_invoice_list"
              element={<Vendor_Invoice_List />}
            />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route
              path="/overview/update_order/:id"
              element={<Update_Order />}
            />
          )}
          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/settings" element={<Settings />} />
          )}
          {this.state.showSuperAdminBoard && (
            <Route path="/settings/super_setting" element={<Super_setting />} />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route
              path="/settings/payment_setting"
              element={<Payment_setting />}
            />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/settings/taxsetting" element={<Taxsetting />} />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route
              path="/roleaccess/updateroleaccess"
              element={<Updateroleaccess />}
            />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/reports/balance_list" element={<Balance_list />} />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/category" element={<CategoryButton />} />
          )}
          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/kot" element={<Kot />} />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/customer_list" element={<Customer_list />} />
          )}
          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/income_list" element={<Income_list />} />
          )}

          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/settings/update_tax/:id" element={<Update_Tax />} />
          )}
          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/receipe" element={<ProductForm />} />
          )}
          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route path="/receipe_list" element={<Receipe_list />} />
          )}
          {(this.state.showAdminBoard || this.state.showUserBoard) && (
            <Route
              path="/receipe/update_recipe/:id"
              element={<Update_Recipe />}
            />
          )}

          {/* ************************************************************************************************** */}
        </Routes>
      </div>
    );
  }
}
export default App;
